<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-form @submit.prevent="submitHandler" ref="form">
        <v-card>
          <v-card-title class="text-h5"
            >{{ isEditing ? 'Update' : 'Add' }} Category</v-card-title
          >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    label="Label"
                    hide-details="auto"
                    v-model="form.label"
                    :error-messages="form.$getError('label')"
                  ></v-text-field>
                </v-col>
                <v-col cols-="12">
                  <v-file-input
                    accept="image/png, image/jpeg"
                    placeholder="Upload photo"
                    label="Photo"
                    v-model="form.photo"
                    @change="selectFile"
                    :error-messages="form.$getError('photo')"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <slot>
              <v-btn @click="closeDialog" text>Cancel</v-btn>
              <v-btn type="submit" color="primary" :loading="form.$busy">
                {{ isEditing ? 'Update' : 'Submit' }}</v-btn
              >
            </slot>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" right>
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text color="green" v-bind="attrs" @click="snackbar.show = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Form from '@/utils/form'
import { mdiClose, mdiPlus } from '@mdi/js'
import Category from '@/models/Category'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    category_to_edit: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialog: this.value,
      form: new Form({
        label: null,
        photo: null
      }),
      activePicker: null,
      snackbar: {
        show: false,
        message: null
      },
      icons: {
        add: mdiPlus,
        close: mdiClose
      }
    }
  },
  computed: {
    isEditing() {
      return this.category_to_edit !== null
    }
  },
  methods: {
    selectFile(file) {
      this.form.photo = file
    },
    submitHandler() {
      if (this.isEditing) {
        this.updateCategory()
      } else {
        this.addCategory()
      }
    },
    addCategory() {
      this.form.$busy = true
      this.form.$clearErrors()
      new Category(this.form)
        .save()
        .then(() => {
          this.dialog = false
          this.showSnackbar('Category was successfully added!')
          this.$refs.form.reset()
          this.form.$clearErrors()
          this.$emit('event')
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },
    async updateCategory() {
      this.form.$busy = true
      this.form.$clearErrors()
      try {
        const params = {
          id: this.category_to_edit.id,
          label: this.form.label,
          photo: this.form.photo
        }
        await this.$store.dispatch('category/updateCategory', params)
        this.dialog = false
        this.showSnackbar('Category was successfully updated!')
        this.$refs.form.reset()
        this.form.$clearErrors()
        this.$emit('event')
        this.$emit('clearToEdit')
        this.form.id = null
      } catch (e) {
        if (e.response.status === 422) {
          this.form.$setErrors(e.response.data.errors)
        }
      } finally {
        this.form.$busy = false
      }
    },
    closeDialog() {
      this.dialog = false
      this.$refs.form.reset()
      this.form.medications = ['']
      this.form.$clearErrors()
      this.$emit('clearToEdit')
      this.form.id = null
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    showSnackbar(message) {
      this.snackbar.message = message
      this.snackbar.show = true
    }
  },
  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    category_to_edit(val) {
      if (this.category_to_edit) {
        this.form = new Form(this.category_to_edit)
      }
    },
    value(val) {
      this.dialog = val
      if (val) {
        this.selectedCountryCode = '61'
      }
    }
  }
}
</script>
